<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow-off" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text v-if="isFirst">
          <div>
            <v-row>
              <v-col class="text-h1 text-center">
                <div class="text-h5">Let us get started on setting up a close cycle</div>
              </v-col>
            </v-row>
            <br>
            <div class="text-subtitle-1 text-center font-weight-bold text-decoration-underline">
              <v-btn small class="pa-3" color="btnColor" @click="$nova.gotoLink({ path: '/financialclose/project/trialbalance/' + id })">
                <span class="white--text pa-2">Get Started</span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <div class="d-flex align-center mb-2">
            <div class="mr-2" v-if="metadata._id">
              <v-chip v-if="stage === 3" small label class="border-on radius-small white--text" color="btnColor">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label class="border-on radius-small white--text" color="btnColor">Pending for review</v-chip>
              <v-chip v-else-if="stage === 0" small label class="border-on radius-small white--text" color="btnColor">Open</v-chip>
            </div>
            <div class="">
              <v-chip v-if="metadata && metadata.managerlist && metadata.managerlist.indexOf(userid) > -1" small label color="#f4f4f4" class="border-on">Manager</v-chip>
              <v-chip v-else small label color="">General</v-chip>
            </div>
            <v-btn class="shadow-off ml-1" small icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <lb-activitylog :config="activitylogconfig"  :url="`/v2/financialclose/project/getlogs/${metadata._id}`" />
                </span>
              </template>
              <span>Activity Log</span>
                </v-tooltip>
            <v-spacer></v-spacer>
          </div>
          <div class="d-flex align-center my-3" v-if="metadata._id">
            <div class="filterlists">
                <div class=" d-flex align-center ">
                    <div class=" border-on radius-all d-flex flex-column align-center justify-center px-4  hoverable" style="width: 15vh; height:4vh">
                        <div class="grey--text text--darken-1 line-1">{{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</div>
                        <div class="font-weight-bold">
                            <v-menu offset-y open-on-hover v-if="LocationsList?.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">
                                        {{ ((selectedLocation && selectedLocation?.name) ?? 'All') }}
                                        <v-icon class="">mdi-chevron-down</v-icon>
                                    </div>
                                </template>
                                <perfect-scrollbar style="max-height: 300px">
                                    <v-list dense class="pa-0 border-left-default">
                                        <template v-for="(v, k) in LocationsList">
                                            <v-list-item @click="filterWithLocation(v)" :key="k">
                                                <v-list-item-title>{{ v.name || 'NA' }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </perfect-scrollbar>
                            </v-menu>
                            <span v-else>NA</span>
                        </div>
                    </div>
                    <div class="text-center py-2 px-1 hoverable">
                        <div>
                            <v-tooltip bottom content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click.stop="resetFilter()">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Reset filters</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                </div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon  class="mr-2 mb-2" v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                <span>Count And Value Tooltip</span>
              </v-tooltip>
            <div class="d-flex align-center mb-2">
              
              <span
                :class="`mr-3 ${typetoggle ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Count</span>
              <v-switch inset dense class="mt-0" color="default" v-model="typetoggle" label="" hide-details></v-switch>
              <span
                :class="`ml-1 ${!typetoggle ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Value</span>
            </div>
            
        </div>
          <div v-if="loading && !metadata._id">
            <v-skeleton-loader type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
          </div>
          <div v-if="metadata._id">
            <v-row>
              <v-col cols="12">
                <div class="my-4">
                  <div class="d-flex align-center mb-1">
                    <div class="display-1 mr-2">
                      {{ (summary.checklist?.total?.[type] || 0) }}%
                    </div>
                    <div class="">
                      <div class="body-1 line-1-2">Tasklist</div> 
                      <div class="line-1-2">Overall Progress</div>
                    </div>
                  </div>
                  <v-progress-linear :value="((summary.checklist?.total?.[type] || 0)) || 0.5" color="teal" height="12"></v-progress-linear>
                </div>
                <div class="mb-2">
                  <div class="d-flex align-center mb-1">
                    <div class="display-1 mr-2">
                      {{ (summary.pbc?.total?.[type] || 0) }}%
                    </div>
                    <div class="">
                      <div class="body-1 line-1-2">Information Requests</div> 
                      <div class="line-1-2">Overall Progress</div>
                    </div>
                  </div>
                  <v-progress-linear :value="((summary.pbc?.total?.[type] || 0)) || 0.5" color="Tabblue" height="12"></v-progress-linear>
                </div>
              </v-col>

              <v-col cols="12">
                <div>
                  <span class="font-weight-bold mr-1">Note:</span>
                  <span>The components depicted below are based on the Chart of Accounts and the Trial Balance uploaded. In case there is no linkage or no trial balance is uploaded then all of the metrics would get categorised as part of Others.</span>
                </div>
              </v-col>

              <template v-for="(v, k) in keytypes">
                <v-col cols="2" :key="k" v-if="(selected===k || !selected) && (summary.checklist?.[k]?.category_detail || []).length > 0">
                  <div :class="`pa-4 radius-on text-center cursor-pointer ${(selected===k)?'':'hoverable'}`" @click="selected=k">
                    <div class="subtitle-2 font-weight-bold mb-2">{{v.name}}</div>
                    <v-progress-circular :rotate="270" :size="120" :width="22" :value="((summary.checklist?.[k]?.[type] || 0)) || 1"
                      :color="v.color" class="title font-weight-bold">
                      {{ (summary.checklist?.[k]?.[type] || 0) }}%
                    </v-progress-circular>
                    <div class="mt-4">
                      <div></div>
                    </div>
                    <div class="text-left d-flex align-center">
                      <div class="title mr-2">
                        {{ (summary.pbc?.[k]?.[type] || 0) }}%
                      </div>
                      <div class="line-1" style="font-size: 0.65rem !important">Information<br/>Requests</div>
                    </div>
                    <v-progress-linear :value="((summary.pbc?.[k]?.[type] || 0)) || 2" color="btnColor" height="12"></v-progress-linear>
                  </div>
                </v-col>
              </template>
              <v-col cols="10" v-if="selected">
                <div class="radius-on cursor-pointer">
                  <div class="d-flex align-center mb-2">
                    <v-btn icon class="mr-2" @click="selected=''"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <span class="body-2 font-weight-bold">Tasklist Category wise summary</span>
                  </div>
                  <div class=" radius-all">
                  <v-simple-table class="FC-Table" dense>
                    <thead>
                      <tr color="btnColor">
                        <th scope="category">Category</th>
                        <th class="text-center" scope="pending">Pending Submission</th>
                        <th class="text-center" scope="awaiting">Pending Approval</th>
                        <th class="text-center" scope="completed">Closed Task</th>
                        <th class="text-center" scope="total">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v,k) in (summary.checklist?.[selected]?.category_detail || [])" :key="k">
                        <td>{{v._id}}</td>
                        <td class="text-center">{{v.pending}}</td>
                        <td class="text-center">{{v.awaiting}}</td>
                        <td class="text-center">{{v.completed}}</td>
                        <td class="text-center">{{v.total}}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-if="!metadata._id && !loading" class="grey--text title text-center pa-8">
            No Summary found
          </div>
        </v-card-text>
      </v-card>
    </div>
    <lb-dialog v-model="showDialogue" width="500">
          <template v-slot:body>
            <div class="">
              <v-row>
                <v-col>
                  <div class="text-body-1">Let us get started on setting up a close cycle</div>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <div class="">                            
              <v-row>
                <v-col class="ma-2 pa-2">
                  <!-- <v-btn small @click="$nova.gotoLink({ path: '/financialclose/project/checklist/' +id })">
                    <span>Skip this for now</span>
                  </v-btn> -->
                  <v-btn small color="fbcPrimary " @click="$nova.gotoLink({ path: '/financialclose/project/trialbalance/' + id }) ">
                    <span>Get Started</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div> 
          </template>
        </lb-dialog>
  </div>
</template>

<script>

export default {
  name: 'financialclose_project_users',
  // props: ['props'],
  data: function () {
    return {
      loading: false,
      metadata: {},
      summary: {},
      color: {},
      keytypes: {},
      selected: "",
      categorydata: [],
      type: "percentcount",
      typetoggle: false,
      LocationsList:[],
      selectedLocation:{},
      activitylogconfig: {
        userassociate: {
          icon: "mdi-plus",
          color: "info",
          displaytext: "User Associated",
        },
        userreplaced: {
          icon: "mdi-account-convert",
          color: "info",
          displaytext: "User Replaced",
        },
        userremoved: {
          icon: "mdi-close",
          color: "error",
          displaytext: "User Removed",
        },
        usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
        projectcreate: {
          icon: "mdi-plus",
          color: "info",
          displaytext: "Created",
        },
        projectopen: {
          icon: "mdi-lock-open-variant",
          color: "info",
          displaytext: "Opened",
        },
        projectclose: {
          icon: "mdi-lock",
          color: "success",
          displaytext: "Closed",
        },
        projectupdated: {
          icon: "mdi-pencil",
          color: "fbcPrimary",
          displaytext: "Updated",
        },
        tbadd: {
          icon: "mdi-table-plus",
          color: "error",
          displaytext: "Trial Balance Added",
        },
        bulkpbcadd: {
          icon: "mdi-file-question-outline",
          color: "",
          displaytext: "Information Request Added",
        },
      },
      isFirst:false,
      showDialogue:false,
    }
  },
  components: {
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.id = this.$route.params.id || "";
      this.userid = this.$store.state.user.id;
      this.selected = "";
      this.axios.post("/v2/financialclose/analysis/getdetails/"+this.id)
        .then((dt) => {
          if (dt.data.status === "success") {
            dt.data.data[0].checklist = dt.data.data[0].checklist || [];
            // let datas = dt.data.data;
            // console.log(datas,"datas");
            if (dt.data.data[0].checklist.length <= 0 && dt.data.data[0].tb.length <=0) {
              this.isFirst=true;
            }
            else {
              this.isFirst = false;
            }
          }
        });
      
      // let colors = this.$nova.getColors(6, 0.5);
      this.keytypes = {
        "income": {name: "Income", color: this.$nova.hexToRgbA("#0f9d58", 0.7)},
        "expense": {name: "Expense", color: this.$nova.hexToRgbA("#f4b400", 0.7)},
        "asset": {name: "Assets", color: this.$nova.hexToRgbA("#4285f4", 0.7)},
        "liability": {name: "Liabilities", color: this.$nova.hexToRgbA("#db4437", 0.7)},
        "equity": {name: "Equity", color: this.$nova.hexToRgbA("#b242c2", 0.7)},
        "other": {name: "Others", color: this.$nova.hexToRgbA("#73739b", 0.7)},
      }
      this.getData();
    },
    getData() {
      this.loading = true;
      this.summary = {};

      return this.axios.post("/v2/financialclose/analysis/getdashboard/" + this.id).then(dt => {
        if (dt.data.status === "success" && dt.data.data.length > 0) {
          let data = dt.data.data[0] || {};
           console.log(data,"data");
           this.$store.commit("setProjectName", data.metadata);
          this.metadata = data.metadata || {};
          this.summary = data.summary || {};
          this.userlist = [];
          this.userlist = this.metadata.userlist || [];
          this.managerlist = [];
          this.managerlist = this.metadata.managerlist || [];
          this.stage = this.metadata.stage;
          this.getLocation();
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    getLocation() {
      this.loading = true;
      this.axios
        .post("/v2/financialclose/location/get")
        .then((ele) => {
          if (ele.data.status === "success") {
            this.LocationsList = ele.data.data || [];
            this.selectedLocation = {};
            console.log(this.LocationsList, "LocationsList");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setUpCloseCycle(){
      this.showDialogue=true;

    },
    filterWithLocation(location){
      this.selectedLocation = location;
      this.getLocationFilter();
    },
    resetFilter(){
      this.getData();
      this.selectedLocation = {}; 
    },
    getLocationFilter() {
      this.summary = {};
      this.loading = true;
      this.axios
        .post("/v2/financialclose/analysis/getdashboard/" + this.id+"?location="+this.selectedLocation._id)
        .then((ele) => {
          if (ele.data.status === "success") {
          let data = ele.data.data[0] || {};
          this.metadata = data.metadata || {};
          this.summary = data.summary || {};
          this.userlist = [];
          this.userlist = this.metadata.userlist || [];
          this.managerlist = [];
          this.managerlist = this.metadata.managerlist || [];
          this.stage = this.metadata.stage;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    finaluserlist() {
      let nl = [];
      for (const i of this.managerlist) {
        let ud = { ...this.userdata[i] };
        ud.type = "manager";
        nl.push(ud);
      }
      for (const i of this.userlist) {
        let ud = { ...this.userdata[i] };
        ud.type = "general";
        nl.push(ud);
      }
      return nl;
    }
  },
  watch: {
    typetoggle: function(d){
      if(d) this.type = "percentvalue"
      else this.type = "percentcount"
    }
  }
}
</script>
<style scoped>
 
</style>
